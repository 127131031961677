<template>
  <div>
    <div class="box">
      <div class="head">
        <div class="tab">
          <div class="tab_item" :class="tableAction == 1 ? 'action' : ''" @click="clickTab(1)">
            提交需求
          </div>
          <div class="tab_item" :class="tableAction == 2 ? 'action' : ''" @click="clickTab(2)">
            报备供给
          </div>
        </div>
        <div class="state">
          <!-- 沟通状态  -->
          <div class="item">
            <div class="text general">沟通状态：</div>
            <div class="button">
              <el-button
                v-for="item in communication"
                :key="item.id"
                @click="clickCommunication(item.id)"
                :class="communicationAction == item.id ? 'click_button' : ''"
                >{{ item.value }}</el-button
              >
            </div>
          </div>

          <!-- 用户等级 -->
          <div class="item">
            <div class="text general">用户等级：</div>
            <div class="button">
              <el-button
                v-for="item in users"
                :key="item.id"
                @click="clickuser(item.id)"
                :class="userAction == item.id ? 'click_button' : ''"
                >{{ item.value }}</el-button
              >
            </div>
          </div>

          <!-- 审核状态 -->
          <div class="item">
            <div class="text general">审核状态：</div>
            <div class="button">
              <el-button
                v-for="item in audit"
                :key="item.id"
                @click="clickaudit(item.id)"
                :class="auditAction == item.id ? 'click_button' : ''"
                >{{ item.value }}</el-button
              >
            </div>
          </div>

          <!-- 提交时间 -->
          <div class="item">
            <div class="text general">提交时间：</div>
            <el-date-picker
              @change="clickTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="submitTime"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
        </div>
      </div>

      <div class="context">
        <!-- 导出和搜索 -->
        <div class="extract_search">
          <!-- 导出 -->
          <div class="extract" @mouseleave="mouseleaveExport">
            <el-button
              :class="extractClickButton == true ? 'deep_button' : 'pure_button'"
              @click="clickExport"
            >
              <img
                v-if="extractClickButton"
                src="../../../../static/images/jr-icon-out-copy.png"
                alt=""
              />
              <img v-else src="../../../../static/images/jr-icon-out-copy (1).png" alt="" />

              批量导出
            </el-button>
          </div>
          <!-- 搜索 -->
          <div class="search">
            <el-input
              v-model="inputVal"
              placeholder="请输入用户昵称，手机号查询"
              clearable
            ></el-input>
            <el-button @click="searchClick" class="deep_button">搜索</el-button>
          </div>
        </div>
        <div class="table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F6F6F6', color: '#333333' }"
            :row-key="(row) => row.id"
            @sort-change="changeTableSort"
          >
            <el-table-column :reserve-selection="true" type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column label="编号" align="center">
              <template slot-scope="scope">{{ scope.row.serialNumber }}</template>
            </el-table-column>

            <el-table-column label="审核状态" align="center">
              <template slot-scope="scope">
                <div class="stay" v-if="scope.row.auditStatus == 0">
                  <div class="stay_circle"></div>
                  待审核
                </div>
                <div class="not_go" v-if="scope.row.auditStatus == 1">
                  <div class="stay_circle"></div>
                  不通过
                </div>
                <div class="stay" v-if="scope.row.auditStatus == 2">
                  <div class="not_circle"></div>
                  已通过
                </div>
              </template>
            </el-table-column>

            <el-table-column :label="activeName == 1 ? '需求名称' : '报备名称'" align="center">
              <template slot-scope="scope">
                <div v-if="activeName == 1">
                  {{ scope.row.demandName }}
                </div>
                <div v-if="activeName == 2">{{ scope.row.supplyName }}</div>
              </template>
            </el-table-column>

            <el-table-column
              :label="activeName == 1 ? '需求描述' : '报备描述'"
              align="center"
              width="268"
            >
              <template slot-scope="scope">
                <div v-if="activeName == 1" class="flexs">
                  <div :class="more == false ? 'yichu' : ''">{{ scope.row.demandDescribe }}</div>
                  <div class="more" @click="clickMore">更多</div>
                </div>
                <div v-if="activeName == 2" class="flexs">
                  <div class="yichu">{{ scope.row.detailedDescription }}</div>
                  <div class="more" @click="clickMore">更多</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="姓名" align="center">
              <template slot-scope="scope">{{ scope.row.userName }}</template>
            </el-table-column>

            <el-table-column label="用户等级" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.whetherMember == 1">普通会员</div>
                <div v-if="scope.row.whetherMember == 5">超级会员</div>
              </template>
            </el-table-column>

            <el-table-column label="提交时间" align="center" sortable="custom">
              <template slot-scope="scope">{{ scope.row.createTime }}</template>
            </el-table-column>

            <el-table-column label="附件" align="center">
              <template slot-scope="scope">
                <div
                  @click="downloadPdf(scope.row.demandFile[0], scope.row.fileName[0])"
                  class="a_click"
                >
                  {{ scope.row.fileName ? scope.row.fileName[0] : '' }}
                </div>
              </template>
            </el-table-column>

            <el-table-column label="沟通状态" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.linkStatus === 0" class="not_relation">未联系</div>
                <div v-if="scope.row.linkStatus === 1" class="already">已联系</div>
              </template>
            </el-table-column>

            <el-table-column label="操作" align="center" width="320">
              <template slot-scope="scope">
                <div class="operate">
                  <div class="left b_click">
                    <div @click="clickAudit(scope.row)">审核</div>
                    <div @click="createdDistribution(scope.row)">创建分销</div>
                  </div>
                  <div class="right b_click">
                    <div @click="clickDistribution(scope.row)">分销管理</div>
                    <div @click="clickParticulars(scope.row)">查看详情</div>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页 -->
          <div class="Pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryDemand.current"
              :page-sizes="[10, 30, 50]"
              :page-size="queryDemand.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 创建分销弹出来的对话框 -->
    <div class="dialog">
      <el-dialog :visible.sync="createdDistributionDialog" width="987px" @close="closeClear">
        <div class="head">
          <div class="capsule"></div>
          <div class="head_text">创建分销</div>
        </div>

        <div class="validTime">
          <div class="text">分销有效期<span>*</span> ：</div>
          <div class="time">
            <el-date-picker
              v-model="dialogTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始"
              end-placeholder="结束"
              value-format="yyyy-MM-dd"
              @change="timeChange"
            >
            </el-date-picker>
          </div>
        </div>

        <div class="search">
          <el-input
            placeholder="请输入姓名查询"
            v-model="dialogInputVla"
            clearable
            style="width: 313px"
          >
          </el-input>
          <el-button @click="dialogClickSearch" class="deep_button">搜索</el-button>
        </div>

        <div class="table">
          <el-table
            ref="multipleTable"
            :data="dialogTableData"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{ background: '#F6F6F6', color: '#333333' }"
            @selection-change="dialogHandleSelectionChange"
          >
            <el-table-column type="selection" width="55" align="center"> </el-table-column>
            <el-table-column label="编号" width="120" align="center">
              <template slot-scope="scope">{{ scope.row.id }}</template>
            </el-table-column>
            <el-table-column label="用户头像" width="120" align="center">
              <template slot-scope="scope">
                <div class="imgaeBox">
                  <img :src="scope.row.headPortrait" alt="" />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="userName" label="姓名" align="center"> </el-table-column>
            <el-table-column prop="address" label="用户等级" align="center">
              <template slot-scope="scope">
                <div>
                  <div v-if="scope.row.whetherMember == 1">普通会员</div>
                  <div v-else>超级会员</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="参与时间" align="center" sortable>
              <template slot-scope="scope">
                {{ scope.row.createTime }}
              </template>
            </el-table-column>
          </el-table>

          <div class="pagination">
            <el-pagination
              @size-change="dialogHandleSizeChange"
              @current-change="dialogHandleCurrentChange"
              :current-page="1"
              :page-sizes="[10, 30, 50]"
              :page-size="10"
              layout="total, sizes, prev, pager, next, jumper"
              :total="dialogtotal"
            >
            </el-pagination>
          </div>
          <div class="button">
            <el-button class="deep_button" @click="BuildSend">生成并发送</el-button>
            <el-button class="pure_button" @click="clickCancel">取消</el-button>
          </div>
        </div>
      </el-dialog>
    </div>

    <!-- 审核 -->
    <div class="audit_dialog">
      <el-dialog @close="closeAuditDialog" :visible.sync="auditDialog" width="30%">
        <div class="head">
          <div class="capsule"></div>
          <div class="head_text">审核</div>
        </div>
        <div class="context">
          <div class="radio">
            <div class="text">通过<span class="span">*</span></div>
            <div>
              <el-radio @change="clcikAuditRadio" v-model="auditRadio" label="1">是</el-radio>
              <el-radio @change="clcikAuditRadio" v-model="auditRadio" label="0">否</el-radio>
            </div>
          </div>

          <div class="reason">
            <div class="text">理由<span class="span">*</span></div>
            <div class="text">
              <div v-if="AuditRadioShow == 1" class="pass"></div>
              <div v-if="AuditRadioShow == 0" class="no_pass">
                <el-input
                  resize="none"
                  type="textarea"
                  placeholder="请输入不通过理由"
                  v-model="auditInput"
                >
                </el-input>
              </div>
            </div>
          </div>
        </div>

        <div class="button">
          <el-button class="deep_button" @click="dialogSubmit">提交</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { getDemand, queryExcel, getSupplyList } from '../../../api/supplyInfo'
import { selectMember, saveDistribution } from '../../../api/distribution'
export default {
  components: {},
  data() {
    //这里存放数据
    return {
      tableAction: 1,
      communication: [
        {
          value: '全部',
          id: '-1'
        },
        {
          value: '已联系',
          id: '1'
        },
        {
          value: '未联系',
          id: '0'
        }
      ],
      // 用户等级
      users: [
        {
          value: '全部',
          id: '999'
        },
        {
          value: '普通会员',
          id: '1'
        },
        {
          value: '超级会员',
          id: '5'
        }
      ],
      // 审核状态
      audit: [
        {
          value: '全部',
          id: '999'
        },
        {
          value: '已通过',
          id: 2
        },
        {
          value: '不通过',
          id: 1
        },
        {
          value: '待审核',
          id: 0
        }
      ],
      // 沟通状态按钮的点击状态值
      communicationAction: -1,
      // 用户等级 按钮的点击状态值
      userAction: 999,
      // 审核状态 按钮的点击状态值
      auditAction: 999,
      // 提交时间
      submitTime: '',
      // table表格选中的数据
      multipleSelection: '',
      // 表格数据
      tableData: [],
      extractClickButton: false, // 元素的类名控制
      inputVal: '', // 请输入用户昵称，手机号查询的vak
      tableIdList: [], // 表格选中数据的id
      // 需求表格查询提交数据
      queryDemand: {
        auditStatus: null,
        createTime: '',
        current: 1,
        key: '',
        linkStatus: -1,
        size: 10,
        userId: null,
        whetherMember: null,
        order: ''
      },
      // 总数据条数
      total: 0,
      // 报备和需求的开关
      activeName: 1,
      // 创建分销的对话框
      createdDistributionDialog: false,
      // 分销有效期
      dialogTime: '',
      // 分销搜索对话框输入的内容
      dialogInputVla: '',
      // 创建分销的table数据
      dialogTableData: [],
      //  创建分销的table总数据条数
      dialogtotal: 0,
      // 创建分销表格选中的数据
      dialogTableIdList: [],
      // 创建分销表格需要的数据
      selectMemberQuery: {
        current: 1,
        size: 10,
        name: '',
        type: 1,
        typeId: '',
        order: ''
      },
      // 审核
      auditData: '',
      // 审核弹框
      auditDialog: false,
      // 审核管理 是否通过
      auditRadio: '1',
      // 理由
      AuditRadioShow: 1,
      // 审核不通过输入的内容
      auditInput: '',
      // 创建分销
      createdDistributionData: {},
      // 更多
      more: false,
      ismore: false
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //   tab点击事件
    clickTab(action) {
      //点击table切换类名
      this.tableAction = action
      this.activeName = action
      // console.log(action)
      this.search()
    },
    // 沟通状态点击
    clickCommunication(id) {
      // 提交需求

      this.communicationAction = id
      this.queryDemand.linkStatus = this.communicationAction
      this.search()

      // 报备供给
      // if (this.activeName == 2) {
      // }
      // console.log(id)
    },

    // 用户等级 点击
    clickuser(id) {
      // 提交需求

      if (id == 999) {
        this.queryDemand.whetherMember = null
        this.userAction = id
        this.search()
      } else {
        this.userAction = id
        this.queryDemand.whetherMember = this.userAction
        this.search()
      }

      // 报备供给
      // if(this.activeName== 2 ){

      // }

      // console.log(id)
    },

    // 审核状态点击
    clickaudit(id) {
      if (id == 999) {
        this.queryDemand.auditStatus = null
        this.auditAction = id
        this.search()
      } else {
        this.queryDemand.auditStatus = id
        this.auditAction = id
        this.search()
      }

      // console.log(id)
    },
    // 选中提交时间
    clickTime() {
      if (this.submitTime !== null) {
        this.queryDemand.createTime = this.submitTime
        this.search()
      } else {
        this.queryDemand.createTime = ''
        this.search()
      }

      // console.log(this.submitTime)
    },
    //表格按钮点击选中事件
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.tableIdList = []
      this.multipleSelection.forEach((item) => {
        this.tableIdList.push(item.id)
      })
      // console.log(this.tableIdList)
    },
    // 表格排序
    changeTableSort(column) {
      // console.log(column.order, 'order')
      if (column.order == 'ascending') {
        this.queryDemand.order = 'asc'
        this.search()
      } else if (column.order == 'descending') {
        this.queryDemand.order = 'desc'
        this.search()
      }
    },
    // 鼠标移开批量导出元素
    mouseleaveExport() {
      this.extractClickButton = false
    },
    // 批量导出
    async clickExport() {
      // 判断有没有勾选数据
      if (!this.tableIdList.length > 0) {
        return this.$message.warning('请勾选数据')
      }
      this.extractClickButton = true
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data: res } = await queryExcel({
        id: this.tableIdList
      })
      if (res) {
        loading.close()
        let blob = new Blob([res])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        a.download = '分销人员列表' + '.xlsx' //指定下载文件名
        // a.download = "test.png";
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
      } else {
        this.$message.error('导出失败!')
        loading.close()
      }
    },
    // 点击搜索
    searchClick() {
      this.queryDemand.key = this.inputVal
      this.search()
    },
    // 表格数据
    async search() {
      if (this.activeName == 1) {
        const { data: res } = await getDemand(this.queryDemand)
        if (res.resultCode === 200) {
          this.tableData = []
          this.tableData = res.data.otherList
          this.total = res.data.total
          // console.log(res)
        }
      }
      if (this.activeName == 2) {
        const { data: res } = await getSupplyList(this.queryDemand)
        if (res.resultCode === 200) {
          this.tableData = []
          this.tableData = res.data.otherList
          this.total = res.data.total
          // console.log(res)
        }
      }
    },
    // 下载附件
    downloadPdf(url, userName) {
      // console.log(url)
      // console.log(userName)
      let blob = new Blob([url])
      let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
      const a = document.createElement('a') //创建a标签
      a.style.display = 'none'
      a.href = href // 指定下载'链接
      a.download = userName //指定下载文件名
      a.click() //触发下载
      window.URL.revokeObjectURL(a.href) //释放URL对象
      if (document.body.contains(a)) {
        document.body.removeChild(a) //释放标签
      }
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)

      this.queryDemand.size = val
      this.search()
    },
    // 分页
    handleCurrentChange(val) {
      this.queryDemand.current = val
      this.search()
      // console.log(`当前页: ${val}`)
    },

    //审核
    clickAudit(row) {
      this.auditData = row
      this.auditDialog = true
      // console.log(this.auditData)
    },
    //创建分销
    createdDistribution(row) {
      // console.log(row)
      this.createdDistributionDialog = true
      this.createdDistributionData = row
      this.selectMemberQuery.typeId = row.id
      this.getSelectMember()
    },
    //分销管理
    clickDistribution(row) {
      // console.log(row)
      this.$router.push({
        path: '/infoPush/distributionManage',
        query: {
          query: JSON.stringify(row)
        }
      })
    },
    //查看详情
    clickParticulars(row) {
      if (this.activeName === 1) {
        this.$router.push({
          path: `/supplyInfo/infoDetail?id=${row.id}`
        })
      } else if (this.activeName === 2) {
        this.$router.push({
          path: `/supplyInfo/supplyInfo?id=${row.id}`
        })
      }

      // console.log(row)
    },
    // 关闭对话框
    closeClear() {
      this.dialogTime = ''
      this.dialogInputVla = ''
    },
    // 分销时间
    timeChange() {
      // console.log(this.dialogTime)
    },
    // 创建分销点击搜索
    dialogClickSearch() {
      // console.log(this.dialogInputVla)
      this.selectMemberQuery.name = this.dialogInputVla
      this.getSelectMember()
    },
    // 创建分销的页数和条数
    dialogHandleSizeChange(val) {
      this.selectMemberQuery.size = val
      this.getSelectMember()
    },
    dialogHandleCurrentChange(val) {
      this.selectMemberQuery.current = val
      this.getSelectMember()
    },
    // 生成并且发送
    async BuildSend() {
      if (this.dialogTableIdList.length === 0) {
        return this.$message.warning('请勾选数据')
      }
      if (this.dialogTime == [] || this.dialogTime == '') {
        return this.$message.warning('请选择分销时间')
      }
      let query = {
        endTime: this.dialogTime[1],
        idList: this.dialogTableIdList,
        startTime: this.dialogTime[0],
        type: 1,
        typeId: this.createdDistributionData.id
      }
      if (this.activeName == 1) {
        query.type = 1
      } else if (this.activeName == 2) {
        query.type = 2
      }

      const { data: res } = await saveDistribution(query)
      if (res.resultCode === 200) {
        this.$message.success('发送成功')
        // 关闭弹出框
        this.createdDistributionDialog = false
      }
    },
    // 取消
    clickCancel() {
      this.createdDistributionDialog = false
    },
    // 创建分销表格选中的数据
    dialogHandleSelectionChange(val) {
      this.dialogTableIdList = []
      // console.log(val)
      val.forEach((item) => {
        this.dialogTableIdList.push(item.id)
      })
      // console.log(this.dialogTableIdList)
    },

    // 获取创建分销表格数据
    async getSelectMember() {
      const { data: res } = await selectMember(this.selectMemberQuery)
      if (res.resultCode === 200) {
        this.dialogTableData = res.data.list
        this.dialogtotal = res.data.total
      }
      // console.log(this.dialogTableData)
    },
    // 审核 是否通过
    clcikAuditRadio(lebel) {
      // console.log(lebel)
      if (lebel == 1) {
        this.AuditRadioShow = lebel
      } else {
        this.AuditRadioShow = lebel
      }
    },
    // 审核 提交
    dialogSubmit() {
      // console.log('敬请期待')
      this.$message('敬请期待!')
    },
    // 审核 退出遮罩层
    closeAuditDialog() {
      this.auditRadio = '1'
      this.AuditRadioShow = '1'
      this.auditInput = ''
    },
    // 更多
    clickMore() {
      this.more = !this.more
    },
    // // 鼠标悬浮事件
    // mouseMouseenter() {
    //   this.ismore = true
    // },
    // // 鼠标移开事件
    // mouseMouseleave() {
    //   this.ismore = false
    // }
  },
  created() {
    this.search()
  },
  mounted() {}
}
</script>
<style scoped lang="less">
.box {
  padding: 16px 15px 0 23px;
  .head {
    height: 113px;
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 16px;
    padding: 0 19px;
    .tab {
      height: 46px;
      display: flex;
      border-bottom: 1px solid #c4c4c4;
      .tab_item {
        height: 100%;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
        color: #333333;
        cursor: pointer;
      }
    }

    .state {
      height: 66px;
      display: flex;
      .item {
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 45px;

        /deep/.el-button {
          padding: 7px 12px;
        }
        /deep/.el-input__inner {
          width: 219px;
          height: 32px;
          line-height: 32px;
        }
        /deep/.el-input__icon {
          line-height: 32px;
        }
      }
    }
  }
  .context {
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 0 19px 0 20px;

    .extract_search {
      box-sizing: border-box;
      width: 100%;
      height: 65px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 50px;

      .extract {
        img {
          width: 16px;
          height: 16px;
          margin-right: 3px;
        }

        /deep/ .el-button span {
          display: flex;
          align-items: center;
        }
      }
      .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /deep/.el-input__inner {
          width: 382px;
          height: 32px;
        }
        /deep/.el-input__suffix {
          display: flex;
          align-items: center;
        }
        /deep/.el-button {
          margin-left: 16px;
        }
      }
    }
    .table {
      width: 100%;
      // height: 100%;
      padding-bottom: 80px;
      position: relative;

      .to_distribution_list {
        color: #7c71e1;
        cursor: pointer;
      }
      .Pagination {
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;

        /deep/.active {
          width: 28px;
          height: 28px;
          border-radius: 2px;
          background-color: #7c71e1;
          color: #fff;
          font-size: 12px;
          min-width: 28px;
          font-weight: 400;
        }
      }
      .operate {
        display: flex;
        align-items: center;

        .left {
          width: 50%;
          height: 22px;
          border-right: 1px solid #eeecec;
          display: flex;
          justify-content: space-evenly;
          font-size: 12px;
        }

        .right {
          display: flex;
          justify-content: space-evenly;
          width: 50%;
          height: 22px;
          font-size: 12px;
        }
      }
    }
  }
}
.dialog {
  .head {
    display: flex;
    transform: translate(-10px, -20px);
    .capsule {
      width: 4px;
      height: 21px;
      border-radius: 10px;
      background-image: linear-gradient(#7563ff, #5974ff);
    }
    .head_text {
      font-size: 16px;
      color: #333;
      margin-left: 12px;
    }
  }

  .validTime {
    height: 67px;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #eeecec;
    .text {
      font-size: 16px;
      color: #333;
      margin-right: 15px;
      span {
        color: #f05252;
      }
    }
  }

  .search {
    height: 73px;
    display: flex;
    align-items: center;
    .deep_button {
      margin-left: 7px;
    }
  }

  // .table {
  // }
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;

    /deep/.active {
      width: 28px;
      height: 28px;
      border-radius: 2px;
      background-color: #7c71e1;
      color: #fff;
      font-size: 12px;
      min-width: 28px;
      font-weight: 400;
    }
  }

  .button {
    display: flex;
    justify-content: center;
  }
  /deep/.el-dialog {
    margin-top: 40vh;
  }
}

.audit_dialog {
  .head {
    display: flex;
    transform: translate(0px, -20px);
    .capsule {
      width: 4px;
      height: 21px;
      border-radius: 10px;
      background-image: linear-gradient(#7563ff, #5974ff);
    }
    .head_text {
      font-size: 16px;
      color: #333;
      margin-left: 12px;
    }
  }
  .context {
    .radio {
      display: flex;
      .text {
        margin-right: 18px;
        margin-bottom: 21px;
      }
    }
    .reason {
      display: flex;
      .text {
        margin-right: 18px;

        .pass {
          width: 257px;
          height: 162px;
          border-radius: 5px;
          border: 1px solid #e1e1e1;
          background-color: #f3f3f3;
        }
        .no_pass {
          /deep/.el-textarea {
            width: 257px;
            height: 162px;
            border: 1px solid #e1e1e1;
            border-radius: 5px;
          }
          /deep/.el-textarea__inner {
            width: 257px;
            height: 162px;
          }
        }
      }
    }
  }
  .button {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 42px;
  }

  /deep/.el-dialog {
    width: 455px;
    height: 352px;
    border-radius: 12px;
  }
}

.imgaeBox {
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    width: 48px;
    height: 48px;
    border-radius: 4px;
  }
}

.action {
  color: #7c71e1 !important;
  font-size: 16px;
  border-bottom: 2px solid #7c71e1;
}

// 颜色333的  大小16的
.general {
  color: #333333;
  font-size: 16px;
}
// 按钮的类名
.click_button {
  background-color: #8598ff;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
}

.span_bottom {
  margin-right: 15px;
}
.pure_button {
  border-radius: 4px;
  border: 1px solid #7c71e1;
  color: #7c71e1;
  font-size: 14px;
}
.pure_button:hover {
  border-radius: 4px;
  border: 1px solid #7c71e1;
  color: #7c71e1;
  background-color: rgba(124, 113, 225, 0.2);
  font-size: 14px;
}

.deep_button {
  border-radius: 4px;
  color: #fff;
  background-color: #7c71e1;
  font-size: 14px;
}
// 已联系
.already {
  display: inline-block;
  width: 64px;
  height: 24px;
  border-radius: 12px;
  background-color: rgba(124, 113, 225, 0.3);
  color: #7c71e1;
}
// 未联系
.not_relation {
  display: inline-block;
  width: 64px;
  height: 24px;
  border-radius: 12px;
  background-color: #c4c4c4;
  color: #333333;
}
// 待审核
.stay {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #7c71e1;
  font-size: 10px;
}
.stay_circle {
  background-color: #7c71e1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
// 不通过
.not_go {
  color: #f56e87;
  font-size: 10px;
}
.not_circle {
  border-radius: 50%;
  background-color: #f56e87;
  width: 8px;
  height: 8px;
}
.a_click {
  color: #428dfe;
  font-size: 12px;
  cursor: pointer;
}
.b_click {
  color: #7c71e1;
  cursor: pointer;
}
.span {
  color: #f05252;
}
.yichu {
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.flexs {
  display: flex;
  justify-content: center;
}
.more {
  width: 28px;
  color: #7c71e1;
  font-size: 10px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
</style>